.contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  padding-top: 10rem;
  color: #121212;
  font-size: 1.125rem;

  &__container {
    flex: 1;
    max-width: 50rem;
    display: flex;
    flex-direction: column;
  }

  &__heading {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  &__sub-heading {
    font-size: 1.25rem;
  }
}
