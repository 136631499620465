$breakpoint-tablet: 800px;
$breakpoint-desktop: 1200px;

.movies-watched {
  display: grid;
  max-width: 1200px;
  margin: auto;
  grid-template-columns: 1fr;
  padding-bottom: 1rem;

  &__container {
    padding-top: 72px;
  }

  @media (min-width: $breakpoint-tablet) {
    grid-template-columns: 1fr 1fr;
    padding: 1rem;
  }

  @media (min-width: $breakpoint-desktop) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__movie {
    background-color: rgba(255, 255, 255, 0.5);
    //font-family: "Rubik", sans-serif;
    //font-weight: 300;
    font-style: normal;
    font-size: 1rem;
    text-rendering: optimizeLegibility;
    padding-bottom: 1rem;
    margin: 1rem;
    margin-bottom: 0;
    //box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    //border-radius: 0.5rem;
    overflow: hidden;

    @media (min-width: $breakpoint-tablet) {
      //padding: 1rem;
      margin-bottom: 1rem;
    }

    p {
      margin: 0;
      padding: 0;
      line-height: 1.5;
    }
  }

  &__heading {
    margin: 0;
    font-style: normal;
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    letter-spacing: -0.0125rem;
  }

  &__image {
    object-fit: cover;
    height: 50vw;
    width: 100%;
    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 1rem;
      height: 25vw;
    }
    @media (min-width: $breakpoint-desktop) {
      margin-bottom: 1rem;
      height: 15vw;
      max-height: 12.5rem;
    }
  }

  &__inner {
    padding: 1rem;
  }

  &__rating-bar {
    margin-bottom: 0.75rem;
    font-size: 0.75rem;
  }
}
