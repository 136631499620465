*,
*:before,
*:after {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}

body {
  font-family: "Inter", sans-serif;
  text-rendering: optimizeLegibility;
  background-color: #f1f1f1;
}
