.home {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  padding-top: 5rem;
  color: #121212;
  font-size: 1.125rem;

  &__container {
    flex: 1;
    max-width: 50rem;
    display: flex;
    flex-direction: column;
  }

  &__heading {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    text-align: center;
    letter-spacing: -0.125rem;
  }

  &__sub-heading {
    font-size: 1.25rem;
  }

  &__profile-picture {
    width: 12rem;
    height: 12rem;
    object-fit: cover;
    object-position: top;
    border-radius: 50%;
    align-self: center;
    margin-top: 1rem;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
      0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
      0 100px 80px rgba(0, 0, 0, 0.07);
  }

  ul {
    list-style: disc;
    li {
      margin-bottom: 0.5rem;
    }
  }

  p {
    line-height: 1.41;
    margin: 0;
    margin-bottom: 1.41rem;
    a {
      color: inherit;
      font-weight: bold;
      box-shadow: inset 0 -2px 0 #f09;
      transition: all 0.3s ease;
      text-decoration: none;
      &:hover {
        background-color: #f09;
      }
    }
  }
}
